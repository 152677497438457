export const ENV = {
  PRODUCTION: {
    KEY: 'production',
    URL: 'https://api.parthhub.com',
  },
  DEVELOPMENT: {
    KEY: 'development',
    URL: 'https://api.parthhub.com',
  },
  LOCAL: {
    KEY: 'local',
    URL: 'http://localhost:8080',
  },
};

export const TOKEN = 'token_parthhub_website';

export const TOTOKEN_GENERATION_TIME = 'token_generation_time_parthhub_website';

export const ROLES = {
  STUDENT: 'student',
  TEACHER: 'teacher',
  INSTITTUTE: 'institute',
  SCHOOL: 'school',
  ADMIN: 'admin',
  TESTER: 'tester',
};

export const FAQS = [
  {
    question: 'Is Parthhub an educational content creator?',
    answers: [
      `We at ParthHub are clear in our vision from the start, we do not create or distribute any educational content of our own.
      We are just a platform for teachers to create and distribute educational content to their students or any
      students in general if the teacher wishes so. In the end, teachers on our platform are the content
      creators in form of tests or study material and they will get recognition for that.<br /><br />`,
      'Although previous exam papers or any free content can be made available to students for practice.',
    ],
  },
  {
    question: 'What Parthhub offers to educators out there?',
    answers: [
      `Our platform is used by educators to teach with their personalized methodology by facilitating the easy creation of tests,
      assignments, or quizzes with complex question formats directly on our fluid editor. We facilitate the conduction of test
      online with dynamic marking scheme and autoevaluation so that teachers can focus on what they are best known for.<br /><br />`,
      `Sharing study material, personal note or announcement is super easy on our platform.
      We even facilitate online teaching by creation of meeting links and schedules.
      We are in continuous development and on our way to release new features, any suggestions to
      improve our platform is more than welcome. Do drop your feedback at
      <a href="mailto:demo@parthhub.com">
        suggestions@parthhub.com
      </a>`,
    ],
  },
  {
    question: 'What kind of questions can be created in tests?',
    answers: [
      `We have done extensive work on test creation and have a wide plethora of options available from single correct,
      multiple correct to descriptive test questions. We provide the easiest solution to create any mathematical equation or scientific
      notaion on our fluid editor. Kindly explore the test creation section and do leave us a comment if something more is needed.<br /><br />`,
      `We are in continuous development and intent to provide the best tools to educators out there.
      In case of any difficulty we also schedule demo for teachers, one can reach for demo at 
      <a href="mailto:demo@parthhub.com">
        demo@parthhub.com
      </a>
      .`,
    ],
  },
  {
    question: 'What Parthhub has for students?',
    answers: [
      `Parths (students) can interact more efficiently with the same teachers they meet on a day to day basis and have
      repository of the content and tests shared by them on their finger tips.
      They can attempt tests and  assignments with clear visibility of their performance.
      We intent to provide more transparent way of communication to the students with their teachers and vice versa.
      We also share free past yeat question papers on our platform for the students to practise.`,
    ],
  },
  {
    question: 'My OTP is not getting delivered for login?',
    answers: [
      `We are really sorry for the inconvenience caused, it can be because of network issues.
      If one have provided email address during signup, we send OTP on your registered email address too.
      Kindly check your email for OTP.`,
      `If issue still persists, reach us at
      <a href="mailto:contact@parthhub.com">
        contact@parthhub.com
      </a>
      , we will try to get the issue resolved on priority.`,
    ],
  },
  {
    question: 'Do students need to create an account after the teacher has invited them?',
    answers: [
      `As soon as a student is invited by a teacher, his/her account is created on our platform and the student can directly
      login through OTP.`,
    ],
  },
];
