import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';

import Menu from '@material-ui/icons/Menu';

import MenuList from './MenuList';
import AppName from '../app/AppName';
import AuthenticationButton from '../app/AuthenticationButton';

import styles from '../../assets/styles/headerStyle';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 99,
    },
    appBar: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
      background: '#fff',
      paddingRight: 15,
      paddingLeft: 15,
      boxShadow: theme.boxShadow,
      // borderBottom: '1px solid #f0f0f0',
      '@media (max-width:460px)': {
        padding: 0,
      // background: 'red',
      },
    },
    toolbar: {
      textAlign: 'left',
      flexWrap: 'wrap',
    },
    toolbarTitle: {
      paddingTop: 10,
      paddingBottom: 5,
      flexGrow: 1,
    },
    logo: {
      objectFit: 'cover',
      display: 'inline',
    },
  };
});

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  // eslint-disable-next-line react/require-default-props
  window: PropTypes.func,
};

function BackToTop(props) {
  const [open, setOpen] = React.useState(false);
  // const [modalOpen, setModalOpen] = React.useState(false)
  const styleClasss = props.classes;
  const classes = useStyles();
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // const onClick = () => {
  //   setModalOpen((prevState) => ({modalOpen: !prevState}));
  // };
  return (
    <>
      {/* <ComingsoonModal open={modalOpen}/> */}
      {/* <CssBaseline /> */}
      <AppBar elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item className={styleClasss.logoContainer}>
              <AppName />
            </Grid>
            <Grid item xs style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {/* <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                  Support
                </Link> */}
              <Hidden xsDown>
                <AuthenticationButton />
              </Hidden>
              <Hidden smUp>
                <Grid container className={classes.linksContainer} style={{ justifyContent: 'flex-end', color: '#888' }}>
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerToggle}
                    >
                      <Menu />
                    </IconButton>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          {/* <Button href="#" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button> */}
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          classes={{
            paper: styleClasss.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <MenuList />
        </Drawer>
      </Hidden>
      <ScrollTop
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Fab size="small" aria-label="scroll back to top" style={{ background: '#0b214f', color: '#fff' }}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}

export default withStyles(styles)(BackToTop);
