import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ScriptTag from 'react-script-tag';

import OrderData from '../components/OrderData';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f6f9fc', height: '100vh',
    },
    payButton: {
      marginTop: 32,
    },
  };
});

const Pay = () => {
  const classes = useStyles();
  // React.useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  return (
    <div className={classes.container}>
      <div>
        <OrderData />
        <div className={classes.payButton}>
          <form>
            <ScriptTag src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_KUMq35QpCy56CI" async />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Pay;
