import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => {
  return {
    text: {
      fontWeight: 400,
      color: '#555',
    },
    bor: {
      width: '70px',
      height: '3px',
      margin: '0px auto',
      background: theme.secondaryColor,
    },
  };
});

const BorText = (props) => {
  const { title, variant } = props;
  const classes = useStyles();
  return (
    <>
      <div>
        <div><Typography variant={variant || 'h4'} className={classes.text}>{title}</Typography></div>
        <div className={classes.bor} />
      </div>
    </>
  );
};

export default BorText;
