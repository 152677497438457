import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import logo from '../../assets/images/transparent_logo.png';

const useStyles = makeStyles((theme) => {
  return {
    link: {
    // margin: theme.spacing(1, 1.5),
    },
    parth: {
      fontSize: '28px',
      fontWeight: 'bold',
      color: theme.primaryColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
      },
    },
    hub: {
      fontSize: '28px',
      fontWeight: 'bold',
      color: theme.darkSecondaryColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
      },
    },
    homeLink: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: 52,
      height: 52,
    },
  };
});

const AppName = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" justify="flex-start">
        <Grid item>
          <Link href="/" className={classes.homeLink}>
            <img
              src={logo}
              className={classes.logo}
              alt="logo"
            />
          </Link>
        </Grid>
        <Grid item>
          <div style={{ paddingLeft: 8 }}>
            <span className={classes.parth}>PARTH </span>
            <span className={classes.hub}>HUB</span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AppName;
