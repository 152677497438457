import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import PageContainer from '../components/app/PageContainer';
import ContactForm from '../components/app/ContactForm';
import svg from '../assets/images/bg-3.svg';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: `#fff url(${svg}) no-repeat left top`,
      backgroundSize: 'cover',
    },
    container: {
      padding: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  };
});

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <PageContainer>
          <div className={classes.container}>
            <ContactForm />
          </div>
        </PageContainer>
      </div>
    </>
  );
};

export default NotFoundPage;
