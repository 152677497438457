import Axios from 'axios';
import config from '../config/config';

import { TOKEN } from '../utils/constants';

const URI = config.serverURL;
const DEFAULT_HEADER = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const POST = 'post';
// const GET = 'get';

const calling = (url, method, data) => {
  Axios.defaults.headers.common.Authorization = localStorage.getItem(TOKEN);
  if (method === POST) {
    return Axios.post(url, data, DEFAULT_HEADER).then((response) => {
      return response.data;
    });
  }
  return Axios.get(url, { params: data }, DEFAULT_HEADER).then((response) => {
    return response.data;
  });
};

const callApi = (url, method, data) => {
  return calling(url, method, data);
};

// eslint-disable-next-line import/prefer-default-export
export function insertContactInfo(data) {
  const url = `${URI}/api/contact/write/insert-or-update`;
  return callApi(url, POST, data);
}
