import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

// import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => {
  return {
    box: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
    },
    input: {
      background: '#fff',
      height: 40,
      paddingLeft: 15,
      paddingRight: 15,
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
      fontSize: 15,
      color: '#555',
      fontFamily: 'Arial',
    },
    icon: {
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
      borderRadius: '50%',
      color: theme.lightSecondaryColor,
      height: 20,
      width: 20,
      padding: 10,
    },
    formControl: {
    },
  };
});

export default function IconInputField(props) {
  const {
    name, value, handleChange, title, type, handleBlur, Icon, placeholder,
  } = props;
  const classes = useStyles();

  const onBlur = () => {
    if (handleBlur) handleBlur();
  };

  return (
    <div>
      <form autoComplete="off" className={classes.formControl}>
        <Box className={classes.box}>
          { Icon ? <Icon className={classes.icon} /> : null }
          <FormControl fullWidth>
            <input
              type={type || 'text'}
              onChange={handleChange}
              label={title}
              variant="outlined"
              name={name}
              value={value}
              onBlur={onBlur}
              className={classes.input}
              placeholder={placeholder}
            />
          </FormControl>
        </Box>
      </form>
    </div>
  );
}
