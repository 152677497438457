import React, { Component } from 'react';
import {
  Typography,
  Link,
} from '@material-ui/core/';

class Copyright extends Component {
  render() {
    return (
      <Typography variant="body2" align="center">
        {'© '}
        Copyright
        {' '}
        {new Date().getFullYear()}
        {' '}
        <Link color="inherit" href="/">
          ParthHub Education.
        </Link>
        {' '}
        All rights reserved.
      </Typography>
    );
  }
}

export default (Copyright);
