import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import logo from '../assets/images/logo.png';

export default () => {
  return (
    <div>
      <Paper className="container" style={{ padding: '0 15px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={logo} alt="logo" />
                  &nbsp;&nbsp;
                  <h2 style={{ padding: 0, margin: 0 }}>ParthHub</h2>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ textAlign: 'right' }}>Order Id:</TableCell>
              <TableCell>
                {uuidv4().split('-')[4]}
                {uuidv4().split('-')[4]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ textAlign: 'right' }}>Amount:</TableCell>
              <TableCell>&#8377; 1499</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ textAlign: 'right' }}>Payment Mode:</TableCell>
              <TableCell>Razorpay</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ textAlign: 'right' }}>Order Date:</TableCell>
              <TableCell>{new Date().toLocaleDateString()}</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell style={{ textAlign: 'right' }}>Validity:</TableCell>
              <TableCell>{`${paymentOrder.recurringSessionsFrequencyDays * paymentOrder.recurringNumber} days`}</TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};
