import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import PageContainer from '../components/app/PageContainer';
import Faqs from '../components/app/Faqs';

const useStyles = makeStyles((theme) => {
  return {
    title: {
      margin: 0,
      marginBottom: 5,
      marginTop: 5,
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    subtitle: {
      margin: 0,
      marginBottom: 5,
      marginTop: 5,
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
      color: '#666',
    },
  };
});

const Faq = () => {
  const classes = useStyles();

  return (
    <>
      <PageContainer>
        <div className={classes.container}>
          <h1 className={classes.title}>FAQs</h1>
          <h3 className={classes.subtitle}>Check some of these common gotchas first in the frequently asked questions</h3>
        </div>
        <Faqs />
      </PageContainer>
    </>
  );
};

export default Faq;
