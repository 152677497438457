import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  ListSubheader,
  List,
  ListItem,
} from '@material-ui/core';

import AppName from '../app/AppName';
import AuthenticationButton from '../app/AuthenticationButton';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: '#ffffff',
      color: '#1f1f1f',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderBottom: '1px solid #f0f0f0',
      marginBottom: 30,
    },
    hearderButton: {
      border: '1px solid #00a382',
      color: '#00a382',
      fontWeight: 'bold',
      width: 200,
      '&:hover': {
        background: '#00a382',
        color: '#fff',
        transition: '0.4s',
      },
    },
    link: {
    // margin: theme.spacing(1, 1.5),
    },
  };
});

export default function NestedList() {
  const classes = useStyles();

  return (
    <List
      subheader={(
        <ListSubheader component="div" id="nested-list-subheader" className={classes.header}>
          <div>
            <AppName />
          </div>
        </ListSubheader>
      )}
      className={classes.root}
    >
      <ListItem>
        <div>
          <AuthenticationButton justify="center" />
        </div>
      </ListItem>
    </List>
  );
}
