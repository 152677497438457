import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import PageContainer from '../components/app/PageContainer';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: 50,
      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },
  };
});

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <>
      <PageContainer>
        <div className={classes.container}>
          <h1 style={{ fontSize: 100, marginBottom: 0 }}>404</h1>
          <h1 style={{ marginTop: 0 }}>Page Not Found!</h1>
          <br />
          <br />
          <p>We’re sorry, the page you requested could not be found. Please go back to homepage.</p>
          <Button variant="contained" href="/" startIcon={<KeyboardBackspaceIcon />}>Go Back Home</Button>
        </div>
      </PageContainer>
    </>
  );
};

export default NotFoundPage;
