import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => {
  return {
    formControl: {
      marginBottom: 15,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    select: {
      background: '#fff',
      height: 40,
      paddingLeft: 10,
      paddingRight: 15,
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
      fontSize: 15,
      color: '#555',
      fontFamily: 'Arial',
      boxSizing: 'content-box',
      // appearance: 'none',
      // // eslint-disable-next-line max-len
      // backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'black\' height=\'24\' viewBox=\'0 0 24 24\' width=\'24\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/><path d=\'M0 0h24v24H0z\' fill=\'none\'/></svg>")',
      // backgroundRepeat: 'no-repeat',
      // backgroundPositionX: '99%',
      // backgroundPositionY: '50%',
      '&:first-child': {
        color: '#555',
      },
    },
    capitalize: {
      textTransform: 'capitalize',
    },
  };
});

export default function SimpleSelect(props) {
  const {
    name, value, handleChange, data, title, selectValue, selectName, isArray, disabled, handleBlur, noDefault,
  } = props;
  const classes = useStyles();

  const _id = selectValue || '_id';
  const displayName = selectName || 'name';

  const onBlur = () => {
    if (handleBlur) handleBlur();
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <select
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled || false}
          className={classes.select}
          onBlur={onBlur}
        >
          {
            noDefault ? null
              : (
                <option value="">
                  {`Select ${title}`}
                </option>
              )
          }
          {
            isArray ? data && data.map((item) => {
              return <option key={item} value={item} className={classes.capitalize}>{item}</option>;
            })
              : data && data.map((item) => {
                return <option key={item[_id]} value={item[_id]}>{item[displayName]}</option>;
              })
          }
        </select>
      </FormControl>
    </div>
  );
}
