import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import IconShape from '../../assets/images/icon_shape.png';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: '42px 30px 0px 30px',
      height: '100%',
      background: '#fff',
      borderRadius: 16,
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
    },
    iconContainer: {
      textAlign: 'center',
      position: 'relative',
      background: `transparent url(${IconShape}) no-repeat center center`,
      height: 100,
    },
    icon: {
      background: (props) => { return props.color; },
      padding: 15,
      borderRadius: 5,
      boxShadow: (props) => { return `${props.shadowColor} 0px 15px 25px, #e6f9fe 0px 5px 10px`; },
      color: '#fff',
      zIndex: 99,
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      top: 0,
      bottom: 0,
    },
    dotContainer: {
      height: 10,
      width: '80%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      margin: 'auto',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
    },
    dot: {
      height: 0,
      borderTop: '2px solid #edebeb',
      width: '100%',
      borderStyle: 'dotted hidden hidden hidden',
    },
    circle: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      background: (props) => { return props.color; },
      boxShadow: (props) => { return `${props.shadowColor} 0px 15px 25px, #e6f9fe 0px 5px 10px`; },
    },
    textContainer: {
      marginTop: 36,
    },
    title: {
      color: '#333',
      fontWeight: 'bold',
      fontSize: 18,
    },
    description: {
      color: '#555',
      fontSize: 15,
      marginTop: 8,
    },
  };
});

export default function FeatureCard(props) {
  const { item } = props;
  const classes = useStyles({ color: item.color, shadowColor: item.shadowColor });

  return (
    <>
      <Grid className={classes.container}>
        <Grid className={classes.iconContainer}>
          <item.icon className={classes.icon} />
          <div className={classes.dotContainer}>
            <div className={classes.circle} />
            <div className={classes.dot} />
            <div className={classes.circle} />
          </div>
        </Grid>
        <Grid className={classes.textContainer}>
          <Typography className={classes.title}>{item.title}</Typography>
          <Typography className={classes.description}>{item.description}</Typography>
        </Grid>
      </Grid>
    </>
  );
}
