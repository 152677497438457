import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

// import LoginIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => {
  return {
    filled: {
      border: `1px solid ${theme.secondaryColor}`,
      color: '#fff',
      borderRadius: 0,
      background: theme.secondaryColor,
      fontWeight: 'bold',
      '&:hover': {
        background: theme.darkSecondaryColor,
        color: '#fff',
        transition: '0.4s',
      },
    },
    outlined: {
      border: `1px solid ${theme.secondaryColor}`,
      color: theme.darkSecondaryColor,
      borderRadius: 0,
      background: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.darkSecondaryColor,
        color: '#fff',
        transition: '0.4s',
      },
    },
    linkBar: {
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      marginLeft: 15,
    },
  };
});

const AuthenticationButton = (props) => {
  const { justify, hide } = props;
  const classes = useStyles();
  // const queryParam = role ? `?role=${role}` : '';
  return (
    <>
      <Grid container className={classes.linkBar} justify={justify || 'flex-end'}>
        {
          hide ? null
            : (
              <Grid item>
                <Grid underline="none" variant="button" color="textPrimary" href="/" className={classes.link}>
                  <Button
                    // startIcon={<LoginIcon />}
                    href="https://app.parthhub.com/select-role"
                    variant="outlined"
                    target="__blank"
                    className={classes.filled}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            )
        }
        <Grid item>
          <Grid underline="none" variant="button" color="textPrimary" href="/" className={classes.link}>
            <Button href="https://app.parthhub.com/select-role" variant="outlined" target="__blank" className={classes.outlined}>Register</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthenticationButton;
