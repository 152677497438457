import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Contact from '../pages/Contact';
import AboutUs from '../pages/AboutUs';
import Faqs from '../pages/Faq';
import App from '../pages/App';
import NotFoundPage from '../pages/404Page';
import Pay from '../pages/Pay';
import CancellationPolicy from '../pages/CancellationPolicy';
import ShippingPolicy from '../pages/ShippingPolicy';

function Body() {
  return (
    <Switch>
      <Route exact path="/" render={() => { return <Home />; }} />
      <Route exact path="/app" render={() => { return <App />; }} />
      <Route exact path="/privacy-policy" render={() => { return <PrivacyPolicy />; }} />
      <Route exact path="/cancellation-and-refund-policy" render={() => { return <CancellationPolicy />; }} />
      <Route exact path="/shipping-and-delivery-policy" render={() => { return <ShippingPolicy />; }} />
      <Route exact path="/contact-us" render={() => { return <Contact />; }} />
      <Route exact path="/about-us" render={() => { return <AboutUs />; }} />
      <Route exact path="/faq" render={() => { return <Faqs />; }} />
      <Route exact path="/pay" render={() => { return <Pay />; }} />
      <Route path="*" render={() => { return <NotFoundPage />; }} />
    </Switch>
  );
}

export default Body;
