import React, { Component } from 'react';
import {
  withStyles,
  Grid,
  Link,
  List,
  ListItem,
} from '@material-ui/core';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import styles from '../../assets/styles/footerStyle';
import Copyright from './Copyright';
import FollowUs from './FollowUs';
import DownloadApp from '../app/DownloadApp';

class Footer extends Component {
  preventDefault = (event) => { return event.preventDefault(); };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.footerContainer}>
          <div className={classes.container}>
            <Grid container>
              <Grid item md={4} sm={4} xs={12}>
                <Grid item xs={12} sm={12} lg={12}>
                  <h3 style={{ textAlign: 'left' }}>Customer Services</h3>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <List component="nav">
                    <ListItem>
                      <Link href="https://merchant.razorpay.com/policy/KRdHLVIGnM82q9/terms" target="_blank" className={classes.flink}>
                        <ArrowRightIcon />
                        {' '}
                        Terms &amp; Conditions
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="/privacy-policy" className={classes.flink}>
                        <ArrowRightIcon />
                        {' '}
                        Privacy Policy
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="/cancellation-and-refund-policy" target="_blank" className={classes.flink}>
                        <ArrowRightIcon />
                        {' '}
                        Cancellation &amp; Refund Policy
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="/shipping-and-delivery-policy" target="_blank" className={classes.flink}>
                        <ArrowRightIcon />
                        {' '}
                        Shipping &amp; Delivery Policy
                      </Link>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <Grid item xs={12}>
                  <h3 style={{ textAlign: 'left' }}>Links</h3>
                </Grid>
                <Grid item xs={12}>
                  <List component="nav" aria-label="contacts">
                    <ListItem>
                      <Link href="/" target="_blank" className={classes.flink}>
                        <ArrowRightIcon />
                        {' '}
                        Blog
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="/about-us" className={classes.flink}>
                        <ArrowRightIcon />
                        {' '}
                        About Us
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="/contact-us" className={classes.flink}>
                        <ArrowRightIcon />
                        {' '}
                        Contact Us
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="/faq" className={classes.flink}>
                        <ArrowRightIcon />
                        {' '}
                        FAQs
                      </Link>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Grid container item md={4} sm={4} xs={12}>
                <Grid item xs={12}>
                  <Grid container>
                    <h3 style={{ textAlign: 'left' }}>Download App</h3>
                  </Grid>
                </Grid>
                <DownloadApp />
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <h3 style={{ textAlign: 'left' }}>Follow us</h3>
                  </Grid>
                  <FollowUs />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container item xs className={classes.copyRight}>
            <Copyright />
          </Grid>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Footer);
