import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { FAQS } from '../../utils/constants';

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles(() => {
  return {
    root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      // marginBottom: -12,
      minHeight: 56,
      padding: 6,
      '&$expanded': {
        minHeight: 56,
        borderBottom: 'none',
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  };
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(2),
      // [theme.breakpoints.down('sm')]: {
      //   padding: 0,
      // },
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  };
})(MuiAccordionDetails);

const useStyles = makeStyles((theme) => {
  return {
    heading: {
      fontWeight: 600,
      textAlign: 'left',
      paddingTop: 15,
      paddingBottom: 15,
    },
    displayPaper: {
      minHeight: 100,
      paddingTop: 15,
      background: 'trasparent',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    title: {
      margin: 0,
      marginBottom: 5,
      marginTop: 5,
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    accordionDetailsContainer: {
      display: 'block',
    },
  };
});

const Faq = () => {
  const classes = useStyles();

  const getHTML = (html) => {
    return (
      <Typography
        dangerouslySetInnerHTML={{ __html: html }}
        align="left"
      />
    );
  };

  return (
    <>
      <Grid>
        <Paper elevation={0} className={classes.displayPaper}>
          {
                FAQS.map((faqObj, index) => {
                  return (
                    <Accordion key={faqObj.question}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {`${index + 1}. ${faqObj.question}`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetailsContainer}>
                        {
                            faqObj.answers.map((answer, indx) => {
                              return (
                                <Grid
                                    // eslint-disable-next-line react/no-array-index-key
                                  key={`${index}_${indx}`}
                                >
                                  {getHTML(answer)}
                                </Grid>
                              );
                            })
                        }
                      </AccordionDetails>
                    </Accordion>
                  );
                })
            }
        </Paper>
      </Grid>
    </>
  );
};

export default Faq;
