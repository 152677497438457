const colors = {
  primaryColor: '#0b214f',
  secondaryColor: '#609cec',
  darkSecondaryColor: '#3596db',
  lightSecondaryColor: '#1da1f2',
  successColor: '#0bb84c',
  orangeColor: '#f26e1d',
  darkColor: '#08121e',
  darkGreen: '#52c2ca',
  darkYellow: '#f9c647',
  lightBlue: '#ecf1fd',
  lightBlue1: '#5edffa',
  blue1: '#6bddfa',
  blue2: '#8cedfb',
  blue3: '#75f0fb',
  blue4: '#62e5f9',
  voilet1: '#bccdfb',
  voilet2: '#9671f7',
  color1: '#6586c5',
  yellow1: '#f9ca47',
  yellow2: '#f7c165',
  pink1: '#f2b8eb',
  green1: '#c7fdfd',
  green2: '#1AF6F6',
  red1: '#ee5845',
  black1: '#738288',
  shadowColor1: '#edebeb',
  shadowColor2: '#f3f3f3',
  redShadow1: '#fceeea',
  voiletShadow1: '#f0edfe',
  blueShadow1: '#e6f9fe',
  blueShadow2: '#aaf0fc',
  pinkShadow1: '#fdf5fd',
  greenShadow1: '#E5FEFE',
  yellowShadow1: '#fcf0d9',
  blackShadow1: '#e0e5e7',
};

export default colors;
