import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Telegram from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Actions from '../../actions';

import contact from '../../assets/images/contact3.png';

import IconInputField from './IconInputField';
import Textarea from './Textarea';
import BorText from './BorText';
import Select from './Select';
import FollowUs from '../footer/FollowUs';
import Snackbar from '../Progress/Snack';

import { contactFormValidationSchema } from '../../utils/util';

const useStyles = makeStyles((theme) => {
  return {
    container: {
    },
    leftContainer: {
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    paperContainer: {
      padding: 30,
      paddingTop: 75,
      paddingBottom: 75,
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
      maxWidth: 450,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },
    img: {
      width: 360,
      height: 'auto',
      marginBottom: -16,
      [theme.breakpoints.down('sm')]: {
        width: 300,
        marginTop: -24,
      },
    },
    icon: {
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
      borderRadius: '50%',
      color: theme.lightSecondaryColor,
      height: 30,
      width: 30,
      padding: 10,
      marginRight: 20,
      background: '#fff',
    },
    submit: {
      backgroundColor: theme.secondaryColor,
      paddingLeft: 30,
      paddingRight: 30,
      color: '#fff',
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
      fontWeight: 'bold',
      '&:hover': {
        background: theme.darkSecondaryColor,
      },
      marginTop: 15,
    },
    title: {
      marginBottom: 30,
    },
    contactContainer: {
      display: 'flex',
      width: 350,
    },
    socialMediaContainer: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 60,
      paddingBottom: 60,
    },
    link: {
      color: '#000',
    },
  };
});

const ContactForm = (props) => {
  const { insertContactInfo, loading } = props;
  const classes = useStyles();
  const getInitialData = () => {
    return {
      name: '', phone: '', email: '', purpose: '', message: '', source: 'web-app',
    };
  };
  const [data, setData] = React.useState(getInitialData());
  const [snack, setSnack] = React.useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const closeSnack = () => {
    setSnack({});
  };

  const submit = () => {
    contactFormValidationSchema.validate(data)
      .then(() => {
        return insertContactInfo(data)
          .then(() => {
            setData(getInitialData());
            setSnack({ open: true, severity: 'success', message: 'Thanks for contacting us. We will get back to you very soon.' });
          });
      })
      .catch((err) => {
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  return (
    <>
      <Snackbar
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <Grid container alignItems="center" className={classes.container}>
        <Grid item md={6} sm={6} xs={12} className={classes.leftContainer}>
          <Grid>
            <img src={contact} alt="img" className={classes.img} />
          </Grid>
          <Grid container justify="center">
            <Grid>
              <Grid container alignItems="center" className={classes.contactContainer}>
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  <Typography align="left">Shivambika residency, Hi-tec city, Hyderabad, Telangana 500081.</Typography>
                </Grid>
              </Grid>
              <a href="tel:+918299704087">
                <Grid container alignItems="center" className={classes.contactContainer}>
                  <Grid item>
                    <PhoneInTalkIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.link} align="left">+91-8299704087</Typography>
                  </Grid>
                </Grid>
              </a>
              <a href="https://wa.me/918299704087" rel="noreferrer" target="_blank">
                <Grid container alignItems="center" className={classes.contactContainer}>
                  <Grid item>
                    <WhatsAppIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.link} align="left">+91-8299704087</Typography>
                  </Grid>
                </Grid>
              </a>
              <a href="mailto:contact@parthhub.com">
                <Grid container alignItems="center" className={classes.contactContainer}>
                  <Grid item>
                    <EmailIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.link} align="left">contact@parthhub.com</Typography>
                  </Grid>
                </Grid>
              </a>
              <Grid container alignItems="center" className={classes.socialMediaContainer}>
                <FollowUs />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={6} sm={6} xs={12} justify="flex-end">
          <Paper elevation={0} className={classes.paperContainer}>
            <Grid className={classes.title}>
              <BorText title="Get in touch with us" variant="h6" />
            </Grid>
            <IconInputField
              Icon={AccountCircle}
              name="name"
              value={data.name}
              handleChange={handleChange}
              placeholder="Enter Full Name"
            />
            <IconInputField
              Icon={PhoneInTalkIcon}
              name="phone"
              type="number"
              value={data.phone}
              handleChange={handleChange}
              placeholder="Enter Phone Number"
            />
            <IconInputField
              Icon={EmailIcon}
              name="email"
              value={data.email}
              handleChange={handleChange}
              placeholder="Enter Email"
            />
            <Select
              title="Purpose"
              data={['Schedule Demo', 'Query', 'Marketing', 'Other']}
              name="purpose"
              value={data.purpose}
              handleChange={handleChange}
              isArray
            />
            <Textarea
              name="message"
              value={data.message}
              handleChange={handleChange}
              placeholder="Enter Message"
              rows={8}
            />
            <Button
              className={classes.submit}
              onClick={submit}
              startIcon={loading ? <CircularProgress size={20} /> : <Telegram />}
              disabled={loading || false}
            >
              Send
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    loading: state.contact.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
