import React from 'react';
import {
  withStyles,
  Grid,
  Link,
} from '@material-ui/core';

import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

import styles from '../../assets/styles/footerStyle';

function FollowUs(props) {
  const { classes } = props;
  return (
    <>
      <Grid container item lg sm xs>
        <Grid item className={classes.leftIconContainer}>
          <Link href="https://www.facebook.com/parthhub" target="_blank">
            <div className={classes.socialIcon}>
              <Facebook />
            </div>
          </Link>
        </Grid>
        <Grid item className={classes.leftIconContainer}>
          <Link href="https://twitter.com/ParthHub" target="_blank">
            <div className={classes.socialIcon}>
              <Twitter />
            </div>
          </Link>
        </Grid>
        <Grid item className={classes.leftIconContainer}>
          <Link href="https://www.instagram.com/parth.hub/" target="_blank">
            <div className={classes.socialIcon}>
              <Instagram />
            </div>
          </Link>
        </Grid>
        <Grid item className={classes.leftIconContainer}>
          <Link href="https://in.pinterest.com/parthhub/" target="_blank">
            <div className={classes.socialIcon}>
              <PinterestIcon />
            </div>
          </Link>
        </Grid>
        <Grid item className={classes.leftIconContainer}>
          <Link href="https://www.linkedin.com/company/parthhub" target="_blank">
            <div className={classes.socialIcon}>
              <LinkedInIcon />
            </div>
          </Link>
        </Grid>
        <Grid item>
          <Link href="https://www.youtube.com/channel/UCtthsQVJZmLw2-Bhx6NGF8g" target="_blank">
            <div className={classes.socialIcon}>
              <YouTubeIcon />
            </div>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(FollowUs);
