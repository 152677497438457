import * as Yup from 'yup';

export const currencyFormat = (amount, currency) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumSignificantDigits: 4,
  }).format(amount);
};

export const contactFormValidationSchema = Yup.object().shape({
  name: Yup.string('Name must be a String').required('Name is required'),
  phone: Yup.string('Phone must be a String').min(10).max(10).required('Phone number is required'),
  email: Yup.string('Email must be a String').email().required('Email is required'),
  purpose: Yup.string('Purpose must be a String').required('Purpose is required'),
  message: Yup.string('Message must be a String').required('Message is required'),
});
