import React from 'react';
import { useCountUp } from 'react-countup';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => {
  return {
    number: {
      fontSize: 32,
      fontFamily: theme.typography.fontFamily,
    },
    container: {
      width: '75%',
      height: 100,
      margin: 15,
      boxShadow: theme.boxShadow,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#555',
      border: `3px solid ${theme.lightBlue}`,
    },
    text: {
      color: '#888',
    },
  };
});

const CountUp = (props) => {
  const { number, title } = props;
  const classes = useStyles();
  const { countUp, start } = useCountUp({
    start: 0,
    end: number,
    duration: 3,
    separator: ',',
  });

  React.useEffect(() => {
    start();
  }, []);
  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.number}>{`${countUp}+`}</Typography>
        <Typography className={classes.text}>{title}</Typography>
      </div>
    </div>
  );
};

export default CountUp;
