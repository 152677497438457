/* eslint-disable max-len */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import PageContainer from '../components/app/PageContainer';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: 50,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    title: {
      margin: 0,
      marginBottom: 5,
      marginTop: 5,
      padding: 0,
      wordSpacing: 5,
    },
    border: {
      borderBottom: '2px solid',
    },
    list: {
      margin: 0,
    },
  };
});

const CancellationPolicy = () => {
  const classes = useStyles();
  return (
    <>
      <PageContainer>
        <div className={classes.container}>
          <h1 className={classes.title}><span className={classes.border}>Cancellation & Refund Policy</span></h1>
          <Grid container justify="flex-start">
            <p>Last updated: July 01, 2021</p>
            <br />
          </Grid>
          <Grid container justify="flex-start">
            <p>
              No cancellations & Refunds are entertained.
            </p>
          </Grid>
        </div>
      </PageContainer>
    </>
  );
};

export default CancellationPolicy;
