/* eslint-disable max-len */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import PageContainer from '../components/app/PageContainer';

import image from '../assets/images/contact_us.gif';
import svg from '../assets/images/bg-3.svg';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: `#fff url(${svg}) no-repeat left top`,
      backgroundSize: 'cover',
      [theme.breakpoints.down('sm')]: {
        background: 'transparent',
      },
    },
    container: {
      padding: 50,
      [theme.breakpoints.down('sm')]: {
        padding: 5,
      },
    },
    title: {
      margin: 0,
      marginBottom: 5,
      marginTop: 5,
      padding: 0,
      // wordSpacing: 5,
      textAlign: 'left',
    },
    para: {
      textAlign: 'left',
      lineHeight: '1.5',
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    list: {
      margin: 0,
    },
    img: {
      borderRadius: 5,
    },
  };
});

const AboutUs = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PageContainer>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <div className={classes.container}>
              <h1 className={classes.title}>ABOUT US</h1>
              <h3 className={classes.title}>What we intend to solve</h3>
              <Grid container justify="flex-start">
                <p className={classes.para}>
                  We at ParthHub believe that education cannot be just recorded content behind a silver screen, but it&#39;s an ecosystem in which our teachers play a major role.
                  We firmly believe that teachers do not just reiterate content from a source but they interact, understand and solve the difficulty of a student using various trivial means.
                  <br />
                  <br />
                  With the current technology seeping into our day-to-day lives, it&#39;s important for our teachers to bring some new means to their interaction.
                  We are here to empower the teacher with our simple yet cutting-edge tools so that our educators can enlighten the path of every Parth (student) out there.
                  <br />
                  <br />
                  We intend to bring students even closer to the same teachers they interact with, on day to day basis using our platform.
                  We as a platform are meant to facilitate and preserve this student-teacher relationship and make it even stronger.
                </p>
              </Grid>
            </div>
          </Grid>
          <Grid container item md={6} sm={12} xs={12} justify="center" alignItems="center">
            <img src={image} alt="img" width="80%" height="auto" className={classes.img} />
          </Grid>
        </Grid>
      </PageContainer>
    </div>
  );
};

export default AboutUs;
