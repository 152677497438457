const initialState = {
  loading: false,
};

export default function exampaperReducer(currentState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'CONTACTING':
      return { ...currentState, loading: true };
    case 'CONTACTED':
      return { ...currentState, loading: false, data: payload.data };
    default:
      return currentState;
  }
}
