import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Header from '../header/Header';
import Footer from '../footer/Footer';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: 50,
      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },
  };
});

const SuccessItem = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <>
      <Header />
      <div className={classes.container}>
        {children}
      </div>
      <Footer />
    </>
  );
};

export default SuccessItem;
