import * as Axios from '../axios/axios';

const notifyUser = function (dispatch, error) {
  dispatch({
    type: 'ERROR',
    payload: {
      error,
    },
  });
  return Promise.reject(error);
};

// eslint-disable-next-line import/prefer-default-export
export function insertContactInfo(data) {
  return (dispatch) => {
    dispatch({
      type: 'CONTACTING',
    });
    return Axios.insertContactInfo(data).then((resData) => {
      dispatch({
        type: 'CONTACTED',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}
