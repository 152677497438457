import {
  autoAdjust,
  appTextColor,
  primaryColor,
} from './main';

// import Background from '../../../images/footer-background.jpg';

const containerFluid = {
  padding: '30px',
  paddingLeft: 60,
  '@media (max-width: 500px)': {
    paddingLeft: 30,
  },
};

const container = {
  ...containerFluid,
};

const footerStyle = {
  footerContainer: {
    color: '#FFF',
    marginTop: 30,
    // background: '#AAA',
    width: '100%',
    // backgroundImage: `url(${Background})`,
    background: '#08121e',
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    // backgroundImage: '-webkit-linear-gradient(30deg, #000 50%, #fff 50%)',
  },
  autoAdjust,
  leftContainer: {
    ...autoAdjust,
    padding: 15,
  },
  playStoreItem: {
    ...autoAdjust,
    justifyContent: 'flex-start',
  },
  appStoreItem: {
    ...autoAdjust,
    marginLeft: 15,
    justifyContent: 'flex-start',
  },
  socialIcon: {
    ...autoAdjust,
    height: 30,
    width: 30,
    borderRadius: 3,
    color: '#aaa',
    '&:hover': {
      color: primaryColor,
    },
    // background: '#888',
  },
  leftIconContainer: { marginRight: 15 },
  innerContainer: { minHeight: 250 },
  copyRight: {
    borderTop: '1px solid #555',
    ...autoAdjust,
    height: 40,
    // background: '#1f1f1f',
    color: appTextColor,
    // color: '#888',
  },
  flink: {
    display: 'flex',
    alignItems: 'center',
    color: appTextColor,
  },
  container,
};

export default footerStyle;
