/* eslint-disable max-len */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import PageContainer from '../components/app/PageContainer';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: 50,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    title: {
      margin: 0,
      marginBottom: 5,
      marginTop: 5,
      padding: 0,
      wordSpacing: 5,
    },
    border: {
      borderBottom: '2px solid',
    },
    para: {
      textAlign: 'left',
      lineHeight: '1.5',
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    list: {
      margin: 0,
    },
  };
});

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <>
      <PageContainer>
        <div className={classes.container}>
          <h1 className={classes.title}><span className={classes.border}>Privacy Policy</span></h1>
          <Grid container justify="flex-start">
            <p>Last updated: July 01, 2021</p>
            <p className={classes.para}>
              This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.

              We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </p>
            <p className={classes.para}>
              <h2 className={classes.title}>Introduction</h2>
              We as creator of ParthHub learning and teaching platform welcomes you on your very own
              Website (www.parthhub.com) and ParthHub Mobile Application collectively referred as
              Platform which is owned and managed by ParthHub Educations. Our platform is designed to
              bring students together, provides content and administration tools at one place and focused
              to make it easy mainly for the tutors. We provide digital support to the educators to create
              their own online classrooms, create their own question papers with complex functionalities
              like equations, time limit, dynamic marking etc., conduct test or exam online,
              inviting/grouping students. ParthHub stores content for future learning and practice test
              papers. We also facilitate the educators for video conferencing and digital learning solutions
              through Website and Mobile Application.
              Your visit and use of platform for student engagement, content delivery, administration tools,
              digital learning solutions through video conferencing, collaboration, and related services,
              support, professional services offered on the Platform (“Services”) are subject to this privacy,
              security and cookies policy (&quot;Privacy Policy&quot;) and other terms and conditions of the Platform.
              This Privacy Policy ensures our firm commitment to your privacy vis-à-vis the protection of
              your information.
            </p>
            <p className={classes.para}>
              <h2 className={classes.title}>General</h2>
              In this Privacy Policy &quot;we&quot;, &quot;our&quot; and &quot;us&quot; refers to ParthHub and &quot;you&quot;, &quot;your&quot; and/or “Users”
              refers to the user (Educators/students/institute/schools) of the Platform. In our privacy policy
              we (ParthHub) value your trust and we safeguard your information that has been provided.
              These policies govern your use of the ParthHub App (‘Application’) and www.parthhub.com
              (‘website’). All the services and products associated by the platform (website and
              application) are managed by the company itself. As terms of policy, we are highly committed
              for your privacy. Information given by you is first consented. Th platform obtains the
              information you provide when you download the Application and register for any service or
              product. When you are registered with us, we identify the customer by their (a) username,
              email address, age, gender, location and phone number, course/service you have selected
              and password; (b) transaction information such as date of payment, purchases, respond to
              any of our offers; (c) information provided when asked for help such as username, age,
              gender, location, email id; (d) information given at the time during attending any class,
              asking doubts, etc.
              Our system also collects information that is sent over the internet without Your control. The
              information we collect and analyse include the Internet protocol (IP) address used to connect
              Your computer to the internet; login; email; password; computer and connection information
              such as browser type and version, operating system, and platform; course enrolled history,
              which we sometimes aggregate with similar information from other users to create features,
              reports such as popular courses, most used features etc; the full Uniform Resource Locator
              (URL) click stream to, and from our Platform, including date and time; cookie number;
              courses or videos You viewed or searched for; the email id You used to call our customer
              service.
              We may use your Personal Data to contact you with newsletters, marketing or promotional
              materials and other information that may be of interest to you. You may opt out of receiving
              any, or all, of these communications from us by following the unsubscribe link.
              <br />
              <br />
              We use software tools such as JavaScript to measure and collect session information,
              including page response times, download errors, length of visits to certain page, course and
              videos, page interaction information (such as scrolling, clicks and mouse overs), and
              methods used to browse away from the page. This company does not retain, share, store or
              use any personal identifiable information for any other uses or for third party.
              This Privacy Policy is an electronic record in the form of an electronic contract formed under
              the Information Technology Act, 2000, rules made thereunder, and any other applicable
              statutes, as amended from time to time. This Privacy Policy does not require any digital
              signature.
              Please read this Privacy Policy carefully. By using the Platform, you indicate, agree and
              acknowledge that you understand, agree and consent to this Privacy Policy and to the
              collection and use of information in accordance with this Privacy Policy.
            </p>
            <p className={classes.para}>
              <h2 className={classes.title}>Collecting and Using Your Personal Data</h2>
              <div>
                <h3 className={classes.title}>Personal data storage</h3>
              </div>
              We will retain your Personal Data only for as long as is necessary for the purposes set out in
              this Privacy Policy. We will retain and use your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required to retain your data to
              comply with applicable laws), resolve disputes, and enforce our legal agreements and
              policies.
              <br />
              <br />
              We will also retain Usage Data for internal analysis purposes. Usage Data is generally
              retained for a shorter period, except when this data is used to strengthen the security or to
              improve the functionality of our Service, or we are legally obligated to retain this data for
              longer time periods.
            </p>
            <p className={classes.para}>
              <div>
                <h3 className={classes.title}>Cookies</h3>
              </div>
              We send cookies (small files containing a string of characters) to your computer, thereby
              uniquely identifying your browser. Cookies are used to track your preferences, help you login
              faster, and aggregated to determine user trends. This data is used to improve our offerings,
              such as providing more content in areas of greater interest to a majority of users. Most
              browsers are initially set up to accept cookies, but you can reset your browser to refuse all
              cookies or to indicate when a cookie is being sent. Some of our features and services may
              not function properly if your cookies are disabled.
              We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.

              You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.

              Cookies can be
              {' '}
              <b>Persistent</b>
              {' '}
              or
              {' '}
              <b>Session</b>
              {' '}
              Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies in the 
              <b>What Are Cookies</b>
              article.
            </p>
            <p className={classes.para}>
              <div>
                <h3 className={classes.title}>Alerts</h3>
              </div>
              We may alert you by email or phone (through SMS or call) to inform you about new service
              offerings of the Company and its subsidiaries/affiliations other information which we feel
              might be useful for you, through the Company or its subsidiaries/affiliates.
            </p>
            <p className={classes.para}>
              <div>
                <h3 className={classes.title}>Public Forums</h3>
              </div>
              When you use certain features on our website like the discussion forums and you post or
              share your personal information such as comments, messages, files, photos, will be
              available to all users, and will be in the public domain. All such sharing of information is done
              at your own risk. Please keep in mind that if you disclose personal information in your profile
              or when posting on our forums this information may become publicly available.
            </p>
            <p className={classes.para}>
              <div>
                <h3 className={classes.title}>Log Information</h3>
              </div>
              When you access our Website, our servers automatically record information that your
              browser sends whenever you visit a website. These server logs may include information
              such as your web request, internet protocol address, browser type, browser language, the
              date and time of your request and one or more cookies that may uniquely identify your
              browser.
            </p>
            <p className={classes.para}>
              <div>
                <h3 className={classes.title}>User Communations</h3>
              </div>
              When you send an email or other communication to us, we may retain those
              communications in order to process your inquiries, respond to your requests and improve
              our Services.
            </p>
            <p className={classes.para}>
              <h2 className={classes.title}>Use of Your Personal Data</h2>
              The Company may use Personal Data for the following purposes:
            </p>
            <ul className={classes.list}>
              <li>
                <p className={classes.para}>To provide and maintain our Service, including to monitor the usage of our Service.</p>
              </li>
              <li>
                <p className={classes.para}>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
              </li>
              <li>
                <p className={classes.para}>
                  For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
                </p>
              </li>
              <li>
                <p className={classes.para}>
                  To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application&#39;s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
                </p>
              </li>
              <li>
                <p className={classes.para}>
                  To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
                </p>
              </li>
            </ul>
            <p className={classes.para}>
              <h2 className={classes.title}>Retention of Your Personal Data</h2>
              The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
              <br />
              <br />
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>
            <p className={classes.para}>
              <h2 className={classes.title}>Your consent</h2>
              We believe that, every user of our Application/Services/products/Website must be in a
              position to provide an informed consent prior to providing any Information required for the
              use of the Application/Services/products/Website. By registering with us, you are expressly
              consenting to our collection, processing, storing, disclosing and handling of your information
              as set forth in this Policy now and as amended by us. Processing, your information in any
              way, including, but not limited to, collecting, storing, deleting, using, combining, sharing,
              transferring and disclosing information, all of which activities will take place in India. If you
              reside outside India your information will be transferred, processed and stored in accordance
              with the applicable data protection laws of India.
            </p>
            <p className={classes.para}>
              <h2 className={classes.title}>Changes to this statement</h2>
              As the Company evolves, our privacy policy will need to evolve as well to cover new
              situations. You are advised to review this Policy regularly for any changes, as continued use
              is deemed approval of all changes.
              <br />
              We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
              <br />
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>
            <p className={classes.para}>
              <h2 className={classes.title}>Contact us</h2>
              If you have any questions about this Privacy Policy, You can contact us:
              By email:
              {' '}
              <b>contact@parthhub.com</b>
            </p>
          </Grid>
        </div>
      </PageContainer>
    </>
  );
};

export default PrivacyPolicy;
