import React from 'react';

import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import styles from '../../assets/styles/footerStyle';

import Appstore from '../../assets/images/appstore.png';
import Playstore from '../../assets/images/playstore.png';

const DownloadApp = (props) => {
  const { classes } = props;
  return (
    <>
      <Grid container>
        <Grid item className={classes.playStoreItem}>
          <Link href="https://play.google.com/store/apps/details?id=com.parthhub" target="_blank">
            <img src={Playstore} height="45" width="auto" alt="playstore" />
          </Link>
        </Grid>
        <Grid item xs className={classes.appStoreItem}>
          <Link href="https://app.parthhub.com/select-role" target="_blank">
            <img src={Appstore} height="45" width="auto" alt="appstore" />
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(DownloadApp);
