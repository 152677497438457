import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import GroupIcon from '@material-ui/icons/Group';
import CampaignIcon from '@mui/icons-material/Campaign';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ForumIcon from '@material-ui/icons/Forum';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import VideocamIcon from '@mui/icons-material/Videocam';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

import Header from '../components/header/Header';
import BoxItem from '../components/app/BoxItem';
import Footer from '../components/footer/Footer';
import FollowUs from '../components/footer/FollowUs';
import CountUp from '../components/CountUp';
// import SpecifyCard from '../components/app/SpecifyCard';
import FeatureCard from '../components/app/FeatureCard';
import ContactForm from '../components/app/ContactForm';
import DownloadApp from '../components/app/DownloadApp';
import Faqs from '../components/app/Faqs';
// import Typed from '../components/app/Typed';

// import home from '../assets/images/bg-3.svg';
import image from '../assets/images/banner.png';
import parthCommunity from '../assets/images/parth_community4.jpeg';

import { ROLES } from '../utils/constants';
import colors from '../theme/colors';

const INSTITUTE = [
  'Institute dashboard access',
  'Teacher dashboard access',
  'Get mobile app for your students',
  'Manage students and teachers',
  'Manage meetings & online classes',
  'View students & teachers progress',
  'Manage attendence & fees',
];

const SCHOOL = [
  'School dashboard access',
  'Teacher dashboard access',
  'Get mobile app for your students',
  'Manage students and teachers',
  'Manage meetings & online classes',
  'View students & teachers progress',
  'Manage attendence & fees',
];

const TEACHER = [
  'Teacher dashboard access',
  'Get mobile app for your students',
  'Create class rooms and batches',
  'Manage students',
  'Add study materials',
  'Create & schedule online tests',
  'View student progress',
];

const STUDENT = [
  'Free dashboard access',
  'Free mobile app access',
  'Take online classes',
  'Daily Practice',
  'Learn, practice and ask doubts',
  'Attempt test series',
  'View performance & progress',
];

const EDUCATOR_FEATURES = [
  {
    title: 'Manage Students',
    description: 'Invite students in multiple batches for a particular standard.',
    color: colors.blue1,
    shadowColor: colors.blueShadow1,
    icon: GroupIcon,
  },
  {
    title: 'Manage Teachers',
    description: 'Invite teachers and assign them to manage different batches.',
    color: colors.voilet2,
    shadowColor: colors.voiletShadow1,
    icon: LocalLibraryIcon,
  },
  {
    title: 'Schedule Online Classes',
    description: 'Schedule online classes or meetings on a repetitive basis.',
    color: colors.pink1,
    shadowColor: colors.pinkShadow1,
    icon: DateRangeIcon,
  },
  {
    title: 'Publish Announcements',
    description: 'Publish any type of announcement for their students in a second.',
    color: colors.red1,
    shadowColor: colors.redShadow1,
    icon: CampaignIcon,
  },
  {
    title: 'Add Study Materials',
    description: 'AI-powered editor to add any kind of study materials for students which includes video links, document links, pdfs, text, etc.',
    color: colors.yellow1,
    shadowColor: colors.yellowShadow1,
    icon: LibraryAddIcon,
  },
  {
    title: 'Create Test Papers',
    // eslint-disable-next-line max-len
    description: 'AI-powered editor to create any kind of question which supports single choice, multiple-choice, subjective, boolean, fill in the blank, etc.',
    color: colors.green2,
    shadowColor: colors.greenShadow1,
    icon: PostAddIcon,
  },
  {
    title: 'Monitor Daily Activity',
    description: 'A teacher can add their daily activity to monitor workload on daily basis.',
    color: colors.lightSecondaryColor,
    shadowColor: colors.shadowColor1,
    icon: DashboardCustomizeIcon,
  },
  {
    title: 'Student Doubts',
    description: 'Students asked doubts can be easily answered through the dashboard.',
    color: colors.black1,
    shadowColor: colors.blackShadow1,
    icon: ForumIcon,
  },
];

const STUDENT_FEATURES = [
  {
    title: 'Subject Wise Learning',
    description: 'Read books, notes, important formulas, etc. whatever are added by your educators.',
    color: colors.blue1,
    shadowColor: colors.blueShadow1,
    icon: LightbulbIcon,
  },
  {
    title: 'Tests & Practices',
    description: 'Practice or attempt mocks and previous year\'s test papers which are added by your educators.',
    color: colors.voilet2,
    shadowColor: colors.voiletShadow1,
    icon: AccessTimeFilledIcon,
  },
  {
    title: 'Daily Practice',
    description: 'Courses are divided into days. Practice each day and achieve the goal.',
    color: colors.pink1,
    shadowColor: colors.pinkShadow1,
    icon: CrisisAlertIcon,
  },
  {
    title: 'Online Classes',
    description: 'Take online classes and counseling sessions through the ParthHub mobile app.',
    color: colors.green2,
    shadowColor: colors.greenShadow1,
    icon: VideocamIcon,
  },
  {
    title: 'Ask Doubts',
    description: 'Ask a doubt by capturing or uploading an image or by typing text.',
    color: colors.yellow1,
    shadowColor: colors.yellowShadow1,
    icon: ChatBubbleIcon,
  },
  {
    title: 'Bookmarks',
    description: 'Bookmark important notes, formulas, and questions for better understanding.',
    color: colors.red1,
    shadowColor: colors.redShadow1,
    icon: BookmarksIcon,
  },
];

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        // background: `#fff url(${home}) no-repeat left top`,
        // backgroundSize: 'cover',
      },
    },
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
      },
    },
    banner: {
      minHeight: 400,
    },
    bannerContainer: {
      paddingTop: 16,
      paddingBottom: 15,
      background: 'transparent',
      textAlign: 'left',
      minHeight: 300,
      paddingLeft: 60,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 5,
        paddingTop: 30,
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
    bannerHeadText: {
      fontWeight: 'bold',
      fontSize: 32,
      color: '#333',
      [theme.breakpoints.down('sm')]: {
        fontSize: 28,
      },
    },
    bannerTextContainer: {
      paddingLeft: 15,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 15,
      },
    },
    boxContainer: {
      padding: 24,
      // background: 'yellow',
    },
    topBoxContainer: {
      padding: 24,
      // background: 'red',
      // marginTop: 24,
      marginBottom: 24,
    },
    text: {
      fontWeight: 400,
      color: '#555',
      fontSize: 28,
      marginTop: 30,
    },
    bor: {
      width: '70px',
      height: '3px',
      margin: '5px auto',
      background: theme.secondaryColor,
    },
    img: {
      // boxShadow: theme.boxShadow,
      width: '90%',
      height: 'auto',
      maxWidth: 600,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    imgCommunity: {
      width: 'auto',
      height: 300,
      maxWidth: '100%',
      boxShadow: theme.boxShadow,
      marginLeft: 15,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'auto',
        marginLeft: 0,
      },
    },
    button: {
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 100,
      fontWeight: 'bold',
      fontSize: 15,
      border: `2px solid ${theme.secondaryColor}`,
      color: '#fff',
      background: theme.secondaryColor,
      '&:hover': {
        background: '#fff',
        color: theme.secondaryColor,
        transition: '0.4s',
      },
    },
    buttonContainer: {
      paddingTop: 15,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    title: {
      color: '#555',
      fontSize: 18,
      paddingTop: 8,
      paddingBottom: 4,
    },
    bannerText: {
      marginTop: 8,
      color: '#555',
      // fontWeight: 'bold',
    },
    linksContainer: {
      marginTop: 48,
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
    highlight: {
      color: theme.darkGreen,
    },
    app: {
      paddingLeft: 75,
      paddingTop: 10,
      paddingBottom: 10,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
      },
    },
    icon: {
      color: theme.successColor,
      fontWeight: 900,
    },
    downloadTitle: {
      color: '#555',
      marginBottom: 4,
    },
    faqs: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    imgContainer: {
      // background: `#fff url(${home}) no-repeat center center`,
      backgroundSize: 'cover',
      [theme.breakpoints.down('sm')]: {
        // background: `#fff url(${home}) no-repeat center center`,
      },
    },
    featureContainer: {
      padding: '0 12px',
      marginBottom: 30,
    },
    featureItem: {
      // margin: 24,
      padding: '36px 16px',
    },
  };
});

function Home() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Header />
        <div className={classes.banner}>
          <Grid container className={classes.bannerContainer} alignItems="center">
            <Grid container item md={6} sm={6} xs={12} className={classes.bannerTextContainer}>
              <Grid item xs={12}>
                <div>
                  <Typography className={classes.bannerHeadText} variant="h6">
                    Where anyone can
                    {' '}
                    <span className={classes.highlight}>teach</span>
                    , anyone can
                    {' '}
                    <span className={classes.highlight}>learn</span>
                    .
                  </Typography>
                </div>
                <Typography className={classes.bannerText}>
                  ParthHub allows educators to create their own classroom within few clicks.
                  Our platform facilitates conducting online classes,
                  inviting and grouping students &amp; teachers, storing content and
                  {' '}
                  <b>hassle-free creation of tests with complex equations</b>
                  {' '}
                  along with their easy allocation and
                  assessment and some added functionalities like
                  {' '}
                  <b>scheduling</b>
                  ,
                  {' '}
                  <b>setting the time limit</b>
                  ,
                  {' '}
                  <b>dynamic marking scheme</b>
                  , etc
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.linksContainer}>
                {/* <div className={classes.buttonContainer}>
                  <Button href="/dashboard/login?role=institute" className={classes.button} endIcon={<ArrowRightAltIcon />} target="_blank">
                    Institute Dashboard Login
                  </Button>
                </div>
                <div className={classes.buttonContainer}>
                  <Button href="/dashboard/login?role=school" className={classes.button} endIcon={<ArrowRightAltIcon />} target="_blank">
                    School Dashboard Login
                  </Button>
                </div>
                <div className={classes.buttonContainer}>
                  <Button href="/dashboard/login?role=teacher" className={classes.button} endIcon={<ArrowRightAltIcon />} target="_blank">
                    Teacher Dashboard Login
                  </Button>
                </div> */}
                <div className={classes.buttonContainer}>
                  <Button href="https://app.parthhub.com/select-role" className={classes.button} endIcon={<ArrowRightAltIcon />} target="_blank">
                    Get Started
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container item md={6} sm={6} xs={12} justify="flex-end" alignItems="flex-start">
              <img src={image} alt="img" className={classes.img} />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container className={classes.app}>
            <Grid item xs={12}>
              <Typography align="left" className={classes.downloadTitle}>
                Download App
                {' '}
                <span className={classes.icon}>&#10004;</span>
                {' '}
                Safe &amp; Free
              </Typography>
            </Grid>
            <Grid item xs={12}><DownloadApp /></Grid>
          </Grid>
        </div>
        <div className={classes.container}>
          <Grid container justify="center" style={{ marginBottom: 60 }}>
            <Grid container item md={3} sm={3} xs={12} justify="center">
              <CountUp number={6} title="institutes" />
            </Grid>
            <Grid container item md={3} sm={3} xs={12} justify="center">
              <CountUp number={2} title="schools" />
            </Grid>
            <Grid container item md={3} sm={3} xs={12} justify="center">
              <CountUp number={161} title="teachers" />
            </Grid>
            <Grid container item md={3} sm={3} xs={12} justify="center">
              <CountUp number={1321} title="students" />
            </Grid>
          </Grid>
          <div>
            <div>
              <div><Typography variant="h4" className={classes.text}>Why choose us?</Typography></div>
              <div className={classes.bor} />
            </div>
            <Grid container justify="center" style={{ padding: 15, paddingBottom: 45 }}>
              <Grid item md={3} sm={3} xs={12}>
                <BoxItem realPrice={0} price={0} role={ROLES.STUDENT} title="Student" strings={STUDENT} />
              </Grid>
              <Grid item md={3} sm={3} xs={12}>
                <BoxItem realPrice={149} price={49} role={ROLES.TEACHER} title="Teacher" strings={TEACHER} />
              </Grid>
              <Grid item md={3} sm={3} xs={12}>
                <BoxItem realPrice={199} price={79} role={ROLES.SCHOOL} title="School" strings={SCHOOL} />
              </Grid>
              <Grid item md={3} sm={3} xs={12}>
                <BoxItem realPrice={199} price={79} role={ROLES.INSTITTUTE} title="Institute" strings={INSTITUTE} />
              </Grid>
              {/* <Grid container item md={12} sm={12} xs={12} justify="center">
                <Grid item md={6} sm={6} xs={12}>
                  <Typography className={classes.title}>For Teachers</Typography>
                  <Grid container justify="center">
                    <SuccessItem title="Unlimited free Access of our dashboard." />
                    <SuccessItem title="Add students and schedule online classes." />
                    <SuccessItem title="Create paper/assignment for the students." />
                  </Grid>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Typography className={classes.title}>For Students</Typography>
                  <Grid container justify="center">
                    <SuccessItem title="Unlimited free Access of our mobile app." />
                    <SuccessItem title="Take online classes with your teachers." />
                    <SuccessItem title="Solve &amp; practice assignments &amp; papers." />
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </div>
          {/* <div>
            <div>
              <div><Typography variant="h4" className={classes.text}>Students Join for free</Typography></div>
              <div className={classes.bor} />
            </div>
            <Grid container spacing={1} justify="center" className={classes.topBoxContainer}>
              <Grid container item md={3} sm={4} xs={12} justify="center" className={classes.boxContainer}>
                <SpecifyCard title="Target JEE Mains" />
              </Grid>
              <Grid container item md={3} sm={4} xs={12} justify="center" className={classes.boxContainer}>
                <SpecifyCard title="Target JEE Advanced" />
              </Grid>
              <Grid container item md={3} sm={4} xs={12} justify="center" className={classes.boxContainer}>
                <SpecifyCard title="Target NEET" />
              </Grid>
              <Grid container item md={3} sm={4} xs={12} justify="center" className={classes.boxContainer}>
                <SpecifyCard title="Class I - XII" />
              </Grid>
            </Grid>
          </div> */}
        </div>
        <div>
          <div><Typography variant="h4" className={classes.text}>Educator Features</Typography></div>
          <div className={classes.bor} />
        </div>
        <div className={[classes.imgContainer, classes.container].join(' ')}>
          <div>
            <Grid container justify="flex-start" className={classes.featureContainer}>
              {
                EDUCATOR_FEATURES.map((item) => {
                  return (
                    <Grid key={item.title} item xs={12} md={3} sm={6} className={classes.featureItem}>
                      <FeatureCard item={item} />
                    </Grid>
                  );
                })
              }
            </Grid>
          </div>
          <div>
            <div style={{ marginTop: 90 }}>
              <div><Typography variant="h4" className={classes.text}>Student Features</Typography></div>
              <div className={classes.bor} />
            </div>
            <Grid container justify="flex-start" className={classes.featureContainer}>
              {
                STUDENT_FEATURES.map((item) => {
                  return (
                    <Grid key={item.title} item xs={12} md={3} sm={6} className={classes.featureItem}>
                      <FeatureCard item={item} />
                    </Grid>
                  );
                })
              }
            </Grid>
          </div>
        </div>
        <div className={classes.container}>
          <div>
            <div>
              <div><Typography variant="h4" className={classes.text}>Join our community</Typography></div>
              <div className={classes.bor} />
              <Grid container alignItems="center" justify="flex-start" style={{ marginTop: 50, marginBottom: 90 }}>
                <Grid item container md={7} sm={6} xs={12} justify="flex-start">
                  <img src={parthCommunity} alt="img" className={classes.imgCommunity} />
                </Grid>
                <Grid container item md={5} sm={6} xs={12} justify="flex-start">
                  <div style={{ textAlign: 'left' }}>
                    <Typography variant="h6" style={{ fontSize: 20 }}>
                      Learn &amp; practice from anywhere with your teacher.
                      <br />
                    </Typography>
                    <Grid container style={{ marginTop: 30, fontSize: 20 }} alignItems="center">
                      Join us: &nbsp;
                      {' '}
                      <FollowUs />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div>
            <div>
              <div><Typography variant="h4" className={classes.text}>Contact Us</Typography></div>
              <div className={classes.bor} />
            </div>
            <Grid container justify="center" style={{ padding: 15 }}>
              <ContactForm />
            </Grid>
          </div>
          <div>
            <div>
              <div><Typography variant="h4" className={classes.text}>FAQs</Typography></div>
              <div className={classes.bor} />
            </div>
            <Grid container justify="center" className={classes.faqs}>
              <Faqs />
            </Grid>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
