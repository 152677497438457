import React from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import Modal from '../components/Modal/Modal';
import DownloadApp from '../components/app/DownloadApp';

const App = ({ location }) => {
  const urlParams = new URLSearchParams(location && location.search);
  const route = urlParams.get('route') || '';
  const [seconds, setSeconds] = React.useState(5);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (route) window.location.href = route;
  }, [route]);

  React.useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0) {
        setOpen(true);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <h3>Redirecting to partthub mobile app deep link...........</h3>
      <Modal open={open} isHideClose>
        <h3>Seems like you don&#39;t have ParthHub app. Please download app.</h3>
        <Grid container justify="center">
          <DownloadApp />
        </Grid>
      </Modal>
    </>
  );
};

export default withRouter(App);
