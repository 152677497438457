import { createMuiTheme } from '@material-ui/core/styles';

import colors from './colors';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto Condensed',
      'sans-serif',
      'MuseoSans-Light',
      'Roboto',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'Verdana',
    ].join(','),
  },
  boxShadow: '0px 3px 16px #455b6320',
  ...colors,
});
