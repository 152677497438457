import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';

import EcoIcon from '@material-ui/icons/Eco';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import AuthenticationButton from './AuthenticationButton';

import { currencyFormat } from '../../utils/util';

const useStyles = makeStyles((theme) => {
  return {
    container: { padding: 15 },
    innerContainer: {
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: '0px 50px 0px 50px',
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
    },
    icon: {
      color: theme.lightSecondaryColor,
      fontWeight: 'bold',
      borderRadius: '50%',
      border: `3px solid ${theme.lightSecondaryColor}`,
      margin: 0,
      padding: 0,
    },
    listIcon: {
      color: theme.lightSecondaryColor,
      boxShadow: theme.boxShadow,
    },
    listContent: {
      // boxShadow: theme.boxShadow,
    },
    item: {
      padding: 0,
    },
    titleContainer: {
      marginTop: 5,
    },
    buttonContainer: {
      marginTop: 20,
      marginBottom: 5,
    },
    title: {
      fontWeight: 'bold',
    },
    price: {
      color: theme.darkGreen,
      fontWeight: 'bold',
    },
    realPrice: {
      fontWeight: 'bold',
      color: '#aaa',
      marginLeft: 4,
    },
    cross: {
      textDecoration: 'line-through',
    },
  };
});

const BoxItem = (props) => {
  const {
    title, strings, role, realPrice, price,
  } = props;
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <Grid container alignItems="center" className={classes.innerContainer}>
        <Grid item xs={12}>
          <CheckCircleIcon className={classes.icon} />
        </Grid>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            {
                strings && strings.map((item) => {
                  return (
                    <ListItem key={item} className={classes.item}>
                      <ListItemIcon style={{ minWidth: 30 }}>
                        <EcoIcon fontSize="small" className={classes.listIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listContent}>
                        <Typography variant="body2">{item}</Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })
            }
            <ListItem className={classes.item}>
              <ListItemIcon style={{ minWidth: 30 }}>
                <EcoIcon fontSize="small" className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText className={classes.listContent}>
                <Typography variant="body2">
                  <Link href="/contact-us" target="__blank">Contact us</Link>
                  &nbsp;for more details.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <Typography variant="h5" className={classes.price}>{price ? currencyFormat(price, 'INR') : 'Free'}</Typography>
              <Typography variant="subtitle2" className={classes.realPrice}>
                &nbsp;
                <span className={classes.cross}>{price ? `${currencyFormat(realPrice, 'INR')}` : '' }</span>
                <span>{realPrice ? ' -/month/student' : '' }</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <AuthenticationButton role={role} hide justify="center" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BoxItem;
